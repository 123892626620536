import {useLoaderData} from '@remix-run/react';
import BasicCard from '../components/cards/BasicCard';
import {LANDING_PAGE_QUERY} from '../queries/LANDING_PAGE_QUERY';
import NewsLetter from '~/components/sections/NewsLetter';
import {LoaderArgs, json} from '@shopify/remix-oxygen';
import {Product} from '@shopify/hydrogen/storefront-api-types';
import {
  FRONT_PAGE_COLLECTION_HANDLE,
  LATEST_COLLECTION_HANDLE,
  FEATURED_COLLECTION_HANDLE,
  BUNDLE_HANDLE,
} from '../consts';
import {twMerge} from 'tailwind-merge';
import {CutOffSvg, TreasureStamp} from '~/assets/Svgs';
import ItemSection from '~/components/ItemSection';
import TreasureBoxHero from '~/components/promotion/TreasureBoxHero';
import SmolCaps from '~/components/promotion/SmolCaps';

export async function loader({context}: LoaderArgs) {
  const data = await context.storefront.query(LANDING_PAGE_QUERY, {
    variables: {
      frontPageCollectionHandle: FRONT_PAGE_COLLECTION_HANDLE,
      bundleName: BUNDLE_HANDLE,
      latestCollectionHandle: LATEST_COLLECTION_HANDLE,
      featuredCollectionHandle: FEATURED_COLLECTION_HANDLE,
    },
  });
  // Handle 404s
  if (!data) {
    throw new Response(null, {status: 404});
  }
  return json({
    data,
  });
}

export default function Index() {
  const {data} = useLoaderData<typeof loader>();
  const frontPageCollection = data?.frontPageCollection?.products?.nodes;

  return (
    <>
      <section className="container pt-5 md:pt-8">
        <TreasureBoxHero
          stock={data.bundle?.stock?.value}
          price={
            data?.bundle?.variants?.nodes[0]?.priceV2?.amount.split('.')[0]
          }
        />
      </section>
      <section className="container container-bottom relative">
        <div className="absolute -translate-x-1/2 -translate-y-1/2 hidden md:block md:-bottom-12 md:-right-20 lg:md:-right-16 lg:-bottom-10 z-20">
          <TreasureStamp className="text-primary animate-spin-slow w-32 md:w-40 " />
        </div>
      </section>
      <ItemSection title="Latest Drops" collection={LATEST_COLLECTION_HANDLE}>
        <div className="flex flex-col lg:flex-row w-full gap-4 sm:gap-6">
          <div className="grid lg:w-1/2 grid-cols-2 gap-4 sm:gap-6 sm:gap-y-12">
            {frontPageCollection &&
              [
                frontPageCollection[5],
                frontPageCollection[3],
                frontPageCollection[0],
              ].map((product: Product, index: number) => (
                <BasicCard
                  product={product}
                  key={product.id}
                  square={!(index === 2)}
                  className={twMerge(index === 2 && 'col-span-2')}
                >
                  {index === 2 && (
                    <CutOffSvg className="w-28 hidden md:block text-background absolute -top-[2px] -scale-y-[1] -right-[2px] z-20" />
                  )}
                </BasicCard>
              ))}
          </div>
          <div className="grid lg:w-1/2 grid-cols-2 gap-6 sm:gap-y-12">
            {frontPageCollection &&
              [
                frontPageCollection[4],
                frontPageCollection[2],
                frontPageCollection[1],
              ].map((product: Product, index: number) => (
                <BasicCard
                  product={product}
                  key={product.id}
                  square={!(index === 0)}
                  className={twMerge(index === 0 && 'col-span-2')}
                >
                  {index === 0 && (
                    <CutOffSvg className="w-28 hidden md:block text-background absolute -bottom-[2px] -scale-x-[1] -left-[2px] z-20" />
                  )}
                </BasicCard>
              ))}
          </div>
        </div>
      </ItemSection>
      <ItemSection
        title="Smol Merch"
        external
        collection="https://shop.smolverse.lol/"
      >
        <SmolCaps />
      </ItemSection>
      <NewsLetter />
    </>
  );
}

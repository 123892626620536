import MCSMOL_CAP_IMAGE from '~/assets/smol_caps/mcsmol_cap.webp';
import SMOL_BEANIE_IMAGE from '~/assets/smol_caps/smol_beanie.webp';
import SMOL_CAP_IMAGE from '~/assets/smol_caps/smol_cap.webp';

const products = [
  {
    image: MCSMOL_CAP_IMAGE,
    title: 'McSmol Cap',
    link: 'https://shop.smolverse.lol/products/five-panel-cap-1',
  },
  {
    image: SMOL_CAP_IMAGE,
    title: 'Smol Cap',
    link: 'https://shop.smolverse.lol/products/smol-cap-navy',
  },
  {
    image: SMOL_BEANIE_IMAGE,
    title: 'Smol Beanie',
    link: 'https://shop.smolverse.lol/products/smol-beanie-1',
  },
];

const SmolCaps = () => {
  return (
    <div className="gap-6 grid grid-cols-2 md:grid-cols-3 md:gap-8 lg:gap-12">
      {products.map((product) => (
        <a
          href={product.link}
          target="_blank"
          rel="noreferrer"
          className="space-y-3 group"
          key={product.title}
        >
          <div className="aspect-square overflow-hidden rounded-2xl">
            <img
              src={product.image}
              alt={product.title}
              className="group-hover:scale-[1.2] h-full w-full object-cover transition-transform transform duration-[.5s] ease-in-out"
            />
          </div>
          <div className="flex items-center justify-between text-night-600">
            <p className="text-xs"></p>
            <p className="text-xs">$29</p>
          </div>
          <h1 className="text-lg text-night-1300 group-hover:text-primary md:text-2xl font-medium leading-[120%] sm:leading-[160%] transition-colors">
            {product.title}
          </h1>
        </a>
      ))}
    </div>
  );
};

export default SmolCaps;

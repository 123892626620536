import {Link} from '@remix-run/react';
import React from 'react';
import {ChevronRightIcon} from '~/assets/Icons';

interface Props {
  title: string;
  collection: string;
  children: React.ReactNode;
  external?: boolean;
}

const ItemSection = ({title, collection, children, external}: Props) => {
  return (
    <div className="container container-bottom space-y-6 text-primary">
      <div className="flex w-full items-center justify-between">
        <h1 className="text-2xl md:text-[40px] heading-leading font-heading">
          {title}
        </h1>
        <Link
          to={external ? collection : `/collection/${collection}`}
          target={external ? '_blank' : ''}
          className="flex items-center gap-1.5 font-medium"
        >
          See All
          <ChevronRightIcon className="w-4" />
        </Link>
      </div>
      {children}
    </div>
  );
};

export default ItemSection;

import {ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

const colors = {
  ruby: 'bg-primary text-night-100',
  honey: 'bg-honey-1100 text-night-1300',
  white: 'bg-night-100 text-night-1300',
  rubyInverted: 'bg-primary/10 text-primary',
};

interface Props {
  color: keyof typeof colors;
  children: ReactNode;
  className?: string;
}

const Tag = ({color, children, className}: Props) => {
  return (
    <div
      className={twMerge(
        'h-9 flex items-center gap-1 w-max rounded-full px-4 font-medium',
        colors[color],
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Tag;

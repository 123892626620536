import React from 'react';
import {Button} from '../primitives/Button';
import {
  CutOffSvg,
  EcommerceLogo,
  HeroCorner,
  ProductBenefitBadge,
} from '~/assets/Svgs';
import Tag from '../primitives/Tag';
import TREASURE_BUNDLE_IMAGE from '~/assets/bundle/box_graphics_hero.png';
import MERCHOOOR_BADGE_IMAGE from '~/assets/bundle/merchooor_badge.png';
import HERO_BACKGROUND_IMAGE from '~/assets/bundle/hero_background.jpg';
import GEM_IMAGE from '~/assets/gem.png';
import {BUNDLE_PRICE} from '~/consts';

const TreasureBoxHero = ({
  stock = 0,
  price = 0,
}: {
  stock: number;
  price: number | string;
}) => {
  return (
    <div
      className="overflow-hidden h-[560px] lg:h-[400px] rounded-2xl w-full flex flex-col justify-between relative"
      style={{
        background: `url(${HERO_BACKGROUND_IMAGE})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute z-20 right-16 lg:right-28 top-0">
        <img
          src={MERCHOOOR_BADGE_IMAGE}
          alt="Merchooor Badge"
          className="absolute-center w-[80%]"
        />
        <ProductBenefitBadge className="w-16 lg:w-24" />
      </div>
      <div className="absolute z-20 right-2 lg:right-4 top-0">
        <img
          src={GEM_IMAGE}
          alt="Merchooor Badge"
          className="absolute-center w-[80%]"
        />
        <ProductBenefitBadge className="w-16 lg:w-24" />
      </div>
      <div className="flex flex-col lg:flex-row gap-2 relative z-30 p-6 lg:p-10">
        <Tag color="white" className="text-primary">
          Limited Edition
        </Tag>
        <Tag color="white">{stock} Available</Tag>
      </div>
      <div className="space-y-6 relative z-10 text-night-100 p-6 lg:p-10 bg-primary lg:bg-transparent">
        <div className="space-y-5">
          {/* <EcommerceLogo className="w-[160px] lg:w-[220px]" /> */}
          <h1 className="text-night-100 font-semibold text-3xl">
            Treasure Trove Bundle
          </h1>
          <p className=" max-w-[300px]">
            Score the whole collection of merch with our exclusive bundle deal.
          </p>
        </div>
        <div className="flex flex-col-reverse sm:flex-row gap-3 sm:gap-6 sm:items-center">
          <Button
            to={`/bundle`}
            className="bg-honey-1100 text-night-1300 border-honey-800 w-full sm:w-auto"
          >
            Shop Now
          </Button>
          <div className="flex gap-3">
            <p className="text-2xl font-medium">${price}</p>
            <p className="text-lg opacity-50 font-medium line-through">
              ${BUNDLE_PRICE}
            </p>
          </div>
        </div>
      </div>
      <img
        src={TREASURE_BUNDLE_IMAGE}
        alt="bundle"
        className="absolute w-[140%] max-w-[612px] lg:max-w-auto scale-[120%] lg:scale-100 -translate-x-1/2 lg:-translate-x-0 left-1/2 lg:left-auto lg:w-full bottom-[280px] lg:inset-x-auto lg:-bottom-16 lg:right-0 lg:max-w-[720px] xl:max-w-[900px]"
      />
      <HeroCorner className="absolute hidden lg:block w-[540px] left-0 bottom-0 text-primary" />
      <CutOffSvg className="w-28 hidden lg:block text-background absolute -bottom-[1px] -right-[1px] z-20" />
    </div>
  );
};

export default TreasureBoxHero;
